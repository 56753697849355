const contactsAdapter = (data) => {
  return {
    title: data.title,
    text: data.text,
  };
};

export default ({ data }) => {
  return {
    contacts: data?.items.map(contactsAdapter),
  };
};
